@import url("https:use.typekit.net/uyg3umz.css");
@import "react-toastify/dist/ReactToastify.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --color-primary: 22 78 99;
  --color-secondary: 226 232 240;
  --color-success: 13 148 136;
  --color-info: 6 182 212;
  --color-warning: 245 158 11;
  --color-pending: 217 119 6;
  --color-danger: 185 28 28;
  --color-light: 241 245 249;
  --color-dark: 30 41 59;
  --color-slate-50: 248 250 252;
  --color-slate-100: 241 245 249;
  --color-slate-200: 226 232 240;
  --color-slate-300: 203 213 225;
  --color-slate-400: 148 163 184;
  --color-slate-500: 100 116 139;
  --color-slate-600: 71 85 105;
  --color-slate-700: 51 65 85;
  --color-slate-800: 30 41 59;
  --color-slate-900: 15 23 42;
}

body {
  margin: 0;
  font-family: "Gotham", -apple-system, BlinkMacSystemFont,
    "Segoe UI, " Roboto ", sans-serif;", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  /* font-size: 100%; */
  box-sizing: border-box;
}

.app-btn,
.redirect-btn,
.link-btn,
.redirect-card {
  cursor: pointer;
}

.app-btn:hover,
.redirect-btn:hover,
.link-btn:hover {
  opacity: 0.6;
}

.redirect-card:hover {
  transform: scale(1.015);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.ant-image-preview-img {
  display: inline-block;
}

.ant-layout {
  background: none;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.header-menu__dropdown {
  position: fixed !important;
}

.ant-spin-container {
  /* background: #e2e2e2; */
}
.ant-layout-content {
  width: 100%;
  background-color: #fff;
}

.ant-tooltip-inner {
  border-radius: 0.6rem;
}

@font-face {
  font-family: "Recoleta";
  src: url("./fonts/Recoleta/Recoleta-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Recoleta";
  src: url("./fonts/Recoleta/Recoleta-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Recoleta";
  src: url("./fonts/Recoleta/Recoleta-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Recoleta";
  src: url("./fonts/Recoleta/Recoleta-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Recoleta";
  src: url("./fonts/Recoleta/Recoleta-Bold.ttf") format("truetype");
  font-weight: 700;
}

/* Gotham FONT-FAMILY */
@font-face {
  font-family: "Gotham";
  src: url("./fonts/Gotham/Gotham-Book.otf");
  font-weight: 400;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/Gotham/Gotham-Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/Gotham/Gotham-Medium.otf");
  font-weight: 600;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/Gotham/Gotham-Bold.otf");
  font-weight: 700;
}

/* Roboto FONT-FAMILY */
@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
}

/* Proxima Nova FONT-FAMILY */
@font-face {
  font-family: "ProximaNova";
  src: url("./fonts/ProximaNova/ProximaNova-regular.otf");
  font-weight: 400;
}

/* Lumios FONT-FAMILY */
@font-face {
  font-family: "Lumios";
  src: url("./fonts/Lumios/Lumios-Marker.otf");
  font-weight: 400;
  font-style: italic;
}

.app-text,
span,
div,
label,
p,
b,
i,
a,
strong,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Gotham";
  margin-bottom: 0;
}

.ant-popover-inner {
  border-radius: 6px;
}

.ant-empty-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-empty-description {
  font-size: 1.6rem;
  font-weight: 500;
  color: #8c91ad;
}

.popup-w-auto {
  width: auto !important;
  transition: none !important;
}

a,
a:link,
a:visited {
  color: #d56544;
}

a:active,
a:hover {
  color: #d56644a9 !important;
}

.swal2-popup {
  font-size: 1rem !important;
}

.swal2-container {
  zoom: 1.5;
  -ms-zoom: 1.5;
}

/* FIX: Sweetalert2 add body padding */
/* .swal2-shown {
  overflow: unset !important;
  padding-right: 0px !important;
} */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
